

@font-face {
    font-family: 'Anek Bangla';
    src: url('/src/assets/fonts/AnekBangla-Regular.woff2') format('woff2'),
        url('/src/assets/fonts/AnekBangla-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Anek Bangla';
    src: url('/src/assets/fonts/AnekBangla-ExtraBold.woff2') format('woff2'),
        url('/src/assets/fonts/AnekBangla-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Anek Bangla';
    src: url('/src/assets/fonts/AnekBangla-Light.woff2') format('woff2'),
        url('/src/assets/fonts/AnekBangla-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Anek Bangla';
    src: url('/src/assets/fonts/AnekBangla-ExtraLight.woff2') format('woff2'),
        url('/src/assets/fonts/AnekBangla-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Anek Bangla';
    src: url('/src/assets/fonts/AnekBangla-Medium.woff2') format('woff2'),
        url('/src/assets/fonts/AnekBangla-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Anek Bangla';
    src: url('/src/assets/fonts/AnekBangla-Bold.woff2') format('woff2'),
        url('/src/assets/fonts/AnekBangla-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Anek Bangla';
    src: url('/src/assets/fonts/AnekBangla-Thin.woff2') format('woff2'),
        url('/src/assets/fonts/AnekBangla-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Anek Bangla';
    src: url('/src/assets/fonts/AnekBangla-SemiBold.woff2') format('woff2'),
        url('/src/assets/fonts/AnekBangla-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Istok Web';
    src: url('/src/assets/fonts/Istok-web/IstokWeb-Bold.woff2') format('woff2'),
        url('/src/assets/fonts/Istok-web/IstokWeb-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Istok Web';
    src: url('/src/assets/fonts/Istok-web/IstokWeb-Regular.woff2') format('woff2'),
        url('/src/assets/fonts/Istok-web/IstokWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Inter';
    src: url('/src/assets/fonts/Inter/Inter-Medium.woff2') format('woff2'),
        url('/src/assets/fonts/Inter/Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/src/assets/fonts/Inter/Inter-Regular.woff2') format('woff2'),
        url('/src/assets/fonts/Inter/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/src/assets/fonts/Inter/Inter-Light.woff2') format('woff2'),
        url('/src/assets/fonts/Inter/Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/src/assets/fonts/Inter/Inter-Bold.woff2') format('woff2'),
        url('/src/assets/fonts/Inter/Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/src/assets/fonts/Inter/Inter-SemiBold.woff2') format('woff2'),
        url('/src/assets/fonts/Inter/Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
.MuiSelect-select:focus {
    background: #fff !important;
}




.App-logo {
    height: 40vmin;
    pointer-events: none;
}
.MuiSelect-select:hover{
    background: transparent;
}
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}
a{
    text-decoration: none;
    color: #000;
}
.header_listing:hover{
    color: #3FD0C9;
    text-decoration: underline;
}
.MuiModal-backdrop{
    background-color: rgba(2, 53, 60, 0.70) !important;
}
.MuiBackdrop-invisible {
    background-color: transparent !important;
}
.MuiPopover-root.MuiMenu-root.MuiModal-root{
    z-index: 130000;}
    
@media (max-width:575px) {
    .MuiTablePagination-actions {
        margin: 0 !important;
    }
    .MuiTablePagination-actions button {
        padding: 5px;
    }
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}